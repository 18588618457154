<template>
  <div class="row">
    <div class="col-2">
      <button class="btn btn-secondary button" @click="sort">
        To original order
      </button>
    </div>

    <div class="col-6">
      <h3>Transition</h3>
      <draggable
        class="list-group"
        tag="ul"
        v-model="list1"
        v-bind="dragOptions"
        @start="isDragging = true"
        @end="isDragging = false"
      >
        <transition-group type="transition" name="flip-list">
          <li
            class="list-group-item" v-for="element in list1" :key="element.order"
          >
            <!-- <i :class=" element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'"
              @click="element.fixed = !element.fixed"
              aria-hidden="true"
            ></i> -->
            {{ element.name }}
          </li>
        </transition-group>
      </draggable>
    </div>

  </div>
</template>

<script>

import draggable from "vuedraggable";
let aTest= ["Wim", "Wimpie", "Willem", "Willempie"];

export default {
  name: "transition-example",
  display: "Transition",
  order: 6,
  components: {
    draggable
  },
  data() {
    return {
      list1: [
        { name: "Chris", order: 0 },
        { name: "Jan",   order: 1 },
        { name: "Klaas", order: 2 }
      ],
      list2: [
        { name: "Christien", order: 3 },
        { name: "Jantien",   order: 4 },
        { name: "Klazien", order: 5 }
      ],
      
      list3: aTest.map((name, index) => {
        return { name, order: index + 1 };
      })
    };
  },
  methods: {
    sort() {
      this.list1 = this.list1.sort((a, b) => a.order - b.order);
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  }
};
</script>

<style>
.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.list-group {
  min-height: 20px;
}

.list-group-item {
  cursor: move;
}

.list-group-item i {
  cursor: pointer;
}
</style>